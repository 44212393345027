html {
  font-size: 1em;
  line-height: normal;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  line-height: normal;
}
#sign-out-btn{
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
}
.allowed-new-line{
  white-space: pre-wrap;
}
.wifimedia-navbar.navbar-item img{
  max-height: 4rem;
}
.modal-card-foot{
  justify-content: flex-end;
}
.devices-tree{
    border: 1px dashed gray;
}
.field-label.has-2x-grow{
  flex-grow: 2;
}
.control.is-inline{
  margin-right: 1em;
}
.control.is-inline:last-child{
  margin-right: 0;
}
@media screen and (min-width: 769px), print {
  .modal-content.is-large-modal,
  .modal-card.is-large-modal {
    width: 720px;
  }
}

@media screen and (min-width: 1024px), print {
  .modal-content.is-large-modal,
  .modal-card.is-large-modal {
    width: 900px;
  }
}